<template>

  <div class="wrapper" style="">

    <section class="px-2 mb-3">
      <div class="form-wrapper mb-0">
        <br />
        <div class="input-group"
          style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;">
          <input @keyup.enter="search" type="text" class="form-control" id="search-input" name="keyword"
            placeholder="Search for games, leagues, events" aria-label="Search for games, leagues, events"
            v-model="to_search">
          <div @click="search" class="input-group-prepend"
            style="border-radius: 5px;background-color: white;color: black;text-align: center;">
            <button style="background-color: var(--yellow);" class="btn btn-sm" type="button"><i
                style="color: var(--grey);" class="bi bi-search mr-1"></i> Search</button>
          </div>
        </div>
      </div>
      <div class="row text-light py-1 px-1 mb-2 d-none text-center search-bar">
        <div class="col-12">
          Search Results
        </div>

      </div>

      <!-- <Games v-bind:search="keywords" v-bind:searchable="searchable"></Games> -->



      <Games v-bind:search="keywords" v-bind:searchable="searchable" v-on:search-results="handleSearchResults">
      </Games>

      <div class="text-center slip-ttxt py-3 d-none">
        <div class="text-blue mb-3">Get the Tucheze.com games booklet for free</div>
        <div>
          <a href="" class="login-button py-2 px-4 ">Download latest games booklet</a>
        </div>
      </div>

      <div v-show="no_results" class="text-light text-center d-none">
        No results found for<br>
        "{{ to_search }}"
      </div>

    </section>

    <BottomNavigation></BottomNavigation>

  </div>
</template>

<script>
//import Menu from './Menu'
const BottomNavigation = () => import('./BottomNavigation.vue')

import Games from "./Games.vue";
// import Triviahunt from "./Triviahunt.vue";

export default {
  name: "Search",
  components: {
    Games,
    BottomNavigation
    // Triviahunt,
  },
  data: function () {
    return {
      searchable: true,
      to_search: "",
      keywords: "",
      sub_page: "",
      results: "",
      busy: false,
      no_results: false,
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "search");
    this.getBonus();
    this.$store.dispatch("resetAllGames");
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2024-05-05T13:06:00");
      const endDate = new Date("2024-05-05T16:30:00");
      return now >= startDate && now <= endDate;
    },
  },
  methods: {
    handleSearchResults(results) {
      this.results = results.length > 0;
      this.no_results = results.length === 0;
    },
    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
        currentFixture === undefined || currentFixture.match_id === undefined
          ? index
          : currentFixture.match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "fixture-id-" + prefix + "-");
    },

    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
    search: function () {
      var vm = this;
      vm.keywords = vm.to_search;
      console.log("KEYWORD====>" + vm.to_search);
      // this.$refs.games.resetSearch();
    },
  },
};
</script>